import React, { useState } from 'react';
import logoImage from '../../static/images/illustrations/bible.png'; 

const Data = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!username || !password) {
            setError('Username and password are required');
            return;
        }
        console.log('Submitting', { username, password });
        window.location.href = 'https://refbibleapp.vercel.app/';
    };

    const formStyle = {
        maxWidth: '300px',
        margin: '0 auto',
        padding: '20px',
        backgroundColor: 'rgba(255, 255, 255, 0.3)', 
        borderRadius: '10px', 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'center', 
        height: '100vh' 
    };

    const inputStyle = {
        width: '100%',
        padding: '10px',
        margin: '10px 0',
        border: '1px solid #fff', 
        borderRadius: '5px',
        backgroundColor: 'transparent',
        color: 'black', 
    };

    const buttonStyle = {
        width: '100%',
        padding: '10px',
        backgroundColor: '#8035CA', 
        color: 'white', 
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        marginTop: '20px' 
    };

    const imageStyle = {
        width: '100px', 
        margin: '20px auto' 
    };

    return (
        <div style={formStyle}>
            <img src={logoImage} alt="Logo" style={imageStyle} /> {/* Logo image */}
            <form onSubmit={handleSubmit}>
                <div>
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Username"
                        style={inputStyle}
                        aria-label="Username"
                    />
                </div>
                <div>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        style={inputStyle}
                        aria-label="Password"
                    />
                </div>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <button type="submit" style={buttonStyle}>
                    Login
                </button>
                <a href="#" style={{ color: 'white', textDecoration: 'none', marginTop: '10px' }}>Forgot password?</a>
            </form>
        </div>
    );
};

export default Data;
